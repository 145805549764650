.App {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 100vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(40px + 2vmin);
  color: rgb(25, 24, 24);
}

.App-link {
  color: #61dafb;
}
